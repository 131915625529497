<template>
  <div class="container">
    <div class="row">
      <div class="list-group col-lg-4 ml-3">
        <div class="product-container-btn">
          <div>
            <button
              type="button"
              class="btn btn-option"
              :class="btPrimaryClass"
              @click="fetchDataMember"
            >
              Thành viên hiện tại
            </button>
            <button
              type="button"
              class="btn btn-option"
              :class="btSecondaryClass"
              @click="fetchDataOldMember"
            >
              Cựu thành viên
            </button>
          </div>
        </div>
        <button
          type="button"
          v-for="(member, index) in members"
          :key="index"
          :id="index"
          @click="clickBtn(index)"
          class="list-group-item list-group-item-action border-btn"
        >
          {{ index + 1 + ". " + member.fullName }}
        </button>
      </div>

      <form class="col-lg-7 ml-1 mr-4" id="form">
        <div class="product-container-btn">
          <div>
            <button
              type="button"
              class="btn btn-option"
              :class="statusCreate"
              @click="btCreate"
            >
              Tạo mới
            </button>
            <button
              type="button"
              class="btn btn-option"
              :class="statusEdit"
              @click="btEdit"
            >
              Chỉnh sửa
            </button>
          </div>
          <div v-if="!typeBtn">
            <button
              class="btn btn-option btn-danger"
              @click.prevent="deleteData"
            >
              <i class="fa fa-trash" aria-hidden="true"></i> Xóa
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="inputAddress">Họ và tên</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nhập tên đầy đủ"
            v-model="inforMember.fullName"
          />
        </div>
        <div class="form-group">
          <div class="form-group mb-2 mr-3">
            <p class="preview-product-title">Hình ảnh</p>
            <input id="inputImageMember" type="file" @change="onFileChange" />

            <div id="preview">
              <img
                class="preview-img"
                v-if="inforMember.linkImage"
                :src="inforMember.linkImage"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="inputAddress">Email</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nhập Email"
            v-model="inforMember.email"
          />
        </div>
        <div class="form-group">
          <label for="inputAddress">Vị trí</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nhập vị trí trong lab"
            v-model="inforMember.position"
          />
        </div>
        <div class="form-group">
          <label for="inputAddress">Chuyên môn</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nhập chuyên môn"
            v-model="inforMember.specialize"
          />
        </div>
        <div class="form-group">
          <label for="inputAddress">Trình độ</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nhập trình độ"
            v-model="inforMember.level"
            required="true"
          />
        </div>
        <button
          type="button"
          class="btn btn-primary mb-4"
          @click="btUpdateData"
        >
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
          Cập nhật
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { members, oldmembers, storage } from "../../firebase-config";
import swal from "sweetalert";

export default {
  data() {
    return {
      btPrimaryClass: " btn-primary",
      btSecondaryClass: " btn-outline-secondary",

      statusCreate: " btn-primary",
      statusEdit: " btn-outline-secondary",

      typeBtn: true,
      typeMemberOrOldMember: true,
      members: [],
      inforMember: {
        //   fullName: ''
      },

      plugins: [
        "advlist autolink lists link image charmap print preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
      ],
    };
  },
  methods: {
    fetchDataMember() {
      this.typeMemberOrOldMember = true;
      this.btPrimaryClass = " btn-primary";
      this.btSecondaryClass = " btn-outline-secondary";
      members.get().then((res) => {
        this.members = res.docs.map((doc) => {
          console.log(doc.data());
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
    fetchDataOldMember() {
      this.typeMemberOrOldMember = false;
      this.btPrimaryClass = " btn-outline-secondary";
      this.btSecondaryClass = " btn-primary";
      oldmembers.get().then((res) => {
        this.members = res.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },

    clickBtn(e) {
      for (var i = 0; i < this.members.length; i++) {
        if (i === e) {
          document.getElementById(i).classList.add("active");
          this.inforMember = this.members[i];
          this.btEdit();
        } else {
          document.getElementById(i).classList.remove("active");
        }
      }
    },
    btCreate() {
      document.getElementById("inputImageMember").value = "";
      this.statusCreate = " btn-primary";
      this.statusEdit = " btn-outline-secondary";
      for (var i = 0; i < this.members.length; i++) {
        document.getElementById(i).classList.remove("active");
      }
      this.inforMember = {};
      this.typeBtn = true;
    },
    btEdit() {
      this.statusCreate = " btn-outline-secondary";
      this.statusEdit = " btn-primary";
      this.typeBtn = false;
    },

    btUpdateData() {
      if (this.typeMemberOrOldMember) {
        if (this.typeBtn) {
          // var $form = document.getElementById("form");
       
            members
              .add({
                email: this.inforMember.email,
                fullName: this.inforMember.fullName,
                level: this.inforMember.level,
                linkImage: this.inforMember.linkImage,
                position: this.inforMember.position,
                specialize: this.inforMember.specialize,
              })
              .then(() => {
                this.inforMember = {};
                this.fetchDataMember();
                swal(
                  "Thành công",
                  "Bạn đã thêm thành viên thành công",
                  "success"
                );
              })
              .catch((error) => {
                swal("Lỗi", error, "error");
              });
          
        } else {
          if (Object.keys(this.inforMember).length === 0) {
            swal(
              "Nhắc nhở",
              "Bạn chưa chọn thành viên để chỉnh sửa",
              "warning"
            );
          } else {
            members
              .doc(this.inforMember.id)
              .update({
                email: this.inforMember.email,
                fullName: this.inforMember.fullName,
                level: this.inforMember.level,
                linkImage: this.inforMember.linkImage,
                position: this.inforMember.position,
                specialize: this.inforMember.specialize,
              })
              .then(() => {
                swal(
                  "Thành công",
                  "Bạn đã chỉnh sửa thông tin thành viên thành công",
                  "success"
                );
              })
              .catch((error) => {
                swal("Lỗi", error, "error");
              });
          }
        }
      } else {
        if (this.typeBtn) {
          // var $form = document.getElementById("form");
          
            oldmembers
              .add({
                email: this.inforMember.email,
                fullName: this.inforMember.fullName,
                level: this.inforMember.level,
                linkImage: this.inforMember.linkImage,
                position: this.inforMember.position,
                specialize: this.inforMember.specialize,
              })
              .then(() => {
                this.inforMember = {};
                this.fetchDataMember();
                swal(
                  "Thành công",
                  "Bạn đã thêm thành viên thành công",
                  "success"
                );
              })
              .catch((error) => {
                swal("Lỗi", error, "error");
              });
          
        } else {
          if (Object.keys(this.inforMember).length === 0) {
            swal(
              "Nhắc nhở",
              "Bạn chưa chọn thành viên để chỉnh sửa",
              "warning"
            );
          } else {
            oldmembers
              .doc(this.inforMember.id)
              .update({
                email: this.inforMember.email,
                fullName: this.inforMember.fullName,
                level: this.inforMember.level,
                linkImage: this.inforMember.linkImage,
                position: this.inforMember.position,
                specialize: this.inforMember.specialize,
              })
              .then(() => {
                swal(
                  "Thành công",
                  "Bạn đã chỉnh sửa thông tin thành viên thành công",
                  "success"
                );
              })
              .catch((error) => {
                swal("Lỗi", error, "error");
              });
          }
        }
      }
    },
    deleteData() {
      if (this.typeMemberOrOldMember) {
        if (Object.keys(this.inforMember).length === 0) {
          swal("Nhắc nhở", "Bạn chưa chọn thành viên để xóa", "warning");
        } else {
          members
            .doc(this.inforMember.id)
            .delete()
            .then(() => {
              this.inforMember = {};
              this.fetchDataMember();
              swal("Thành công", "Bạn đã xóa sản phẩm thành công", "success");
            })
            .catch((error) => {
              swal("Lỗi", error, "error");
            });
        }
      } else {
        if (Object.keys(this.inforMember).length === 0) {
          swal("Nhắc nhở", "Bạn chưa chọn thành viên để xóa", "warning");
        } else {
          oldmembers
            .doc(this.inforMember.id)
            .delete()
            .then(() => {
              this.inforMember = {};
              this.fetchDataMember();
              swal("Thành công", "Bạn đã xóa sản phẩm thành công", "success");
            })
            .catch((error) => {
              swal("Lỗi", error, "error");
            });
        }
      }
    },
    onFileChange(e) {
      this.inforMember.linkImage = e.target.files[0];
      var number = this.members.length + 1;
      if (this.typeMemberOrOldMember) {
        var nameImage = "Thành viên " + number;
      } else {
        nameImage = "Thành viên cũ " + number;
      }
      const ref = storage
        .ref()
        .child(nameImage)
        .put(this.inforMember.linkImage);
      ref.on(
        "state_change",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // this.linkBanner = URL.createObjectURL(this.fileBanner);
        },
        () => {
          swal("Lỗi", "Không tải được ảnh lên", "error");
        },
        () => {
          this.uploadValue = 100;
          ref.snapshot.ref.getDownloadURL().then((url) => {
            this.inforMember.linkImage = url;
            console.log(this.inforMember.linkImage);
          });
        }
      );
    },
  },
  created() {
    this.fetchDataMember();
  },
};
</script>

<style>
h4 {
  margin-top: 8px !important;
  text-align: center !important;
}
.product-container-btn {
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
}
</style>
