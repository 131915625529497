<template>
  <div>
    <div class="product-container-btn">
      <div class="">
        <button
          type="button"
          class="btn btn-option"
          :class="statusIntro"
          @click="btIntro"
        >
          Giới thiệu
        </button>
        <button
          type="button"
          class="btn btn-option"
          :class="statusStudy"
          @click="btStudy"
        >
          Hướng nghiên cứu
        </button>
        <button
          type="button"
          class="btn btn-option"
          :class="statusService"
          @click="btService"
        >
          Dịch vụ
        </button>
        <button
          type="button"
          class="btn btn-option"
          :class="statusBanner"
          @click="btBanner"
        >
          Banner
        </button>
      </div>
      <div>
        <button class="btn btn-option btn-danger" @click="updateDataIntro">
          <i class="fa fa-paper-plane" aria-hidden="true"></i> Cập nhật
        </button>
      </div>
    </div>
    <div v-if="!checkFormUpdateBanner">
      <div class="title ml-3 mr-5">
        <p v-html="des"></p>
      </div>
      <div style="height: 6vh"></div>

      <div class="editer ml-3 mr-3 mb-5">
        <tinymce
          id="des"
          :plugins="plugins"
          v-model="des"
          style="height: 500px !important"
        />
      </div>
    </div>
    <div v-else>
      <form class="mt-30 ml-3">
        <!-- hình ảnh banner -->
        <div class="form-group mb-2 mr-3">
          <h5 class="preview-title">Hình ảnh banner</h5>
          <input type="file" @change="onFileChangeBanner" />

          <div id="preview">
            <img class="preview-img" v-if="linkBanner" :src="linkBanner" />
          </div>
        </div>
        <!-- hình ảnh giới thiệu 1 -->
        <div class="form-group mb-2 mr-3">
          <h5 class="preview-title">Hình ảnh giới thiêu 1</h5>
          <input type="file" @change="onFileChangeImage1" />

          <div id="preview">
            <img class="preview-img" v-if="linkImage1" :src="linkImage1" />
          </div>
        </div>
        <!-- hình ảnh giới thiêu 2 -->
        <div class="form-group mb-2 mr-3">
          <h5 class="preview-title">Hình ảnh giới thiệu 2</h5>
          <input type="file" @change="onFileChangeImage2" />

          <div id="preview">
            <img class="preview-img" v-if="linkImage2" :src="linkImage2" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
import { introduceemg, storage } from "../../firebase-config";
import swal from "sweetalert";

export default {
  components: {
    tinymce,
  },
  data() {
    return {
      statusIntro: " btn-outline-secondary",
      statusStudy: " btn-outline-secondary",
      statusService: " btn-outline-secondary",
      statusBanner: " btn-outline-secondary",
      typeUpdate: 0,
      checkFormUpdateBanner: false,
      linkBanner: null,
      linkImage1: null,
      linkImage2: null,
      introduce: [],
      des: "",
      plugins: [
        "advlist autolink lists link image charmap print preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
      ],
    };
  },
  methods: {
    btIntro() {
      this.statusIntro = " btn-primary";
      this.statusStudy = " btn-outline-secondary";
      this.statusService = " btn-outline-secondary";
      this.statusBanner = " btn-outline-secondary";
      this.des = this.introduce[0].intro;
      this.typeUpdate = 1;
      this.checkFormUpdateBanner = false;
    },
    btStudy() {
      this.statusIntro = " btn-outline-secondary";
      this.statusStudy = " btn-primary";
      this.statusService = " btn-outline-secondary";
      this.statusBanner = " btn-outline-secondary";
      this.des = this.introduce[0].perpose;
      this.typeUpdate = 2;
      this.checkFormUpdateBanner = false;
    },
    btService() {
      this.statusIntro = " btn-outline-secondary";
      this.statusStudy = " btn-outline-secondary";
      this.statusService = " btn-primary";
      this.statusBanner = " btn-outline-secondary";
      this.des = this.introduce[0].service;
      this.typeUpdate = 3;
      this.checkFormUpdateBanner = false;
    },
    btBanner() {
      this.statusIntro = " btn-outline-secondary";
      this.statusStudy = " btn-outline-secondary";
      this.statusService = " btn-outline-secondary";
      this.statusBanner = " btn-primary";
      this.linkBanner = this.introduce[0].linkBanner;
      this.linkImage1 = this.introduce[0].linkImage1;
      this.linkImage2 = this.introduce[0].linkImage2;
      this.typeUpdate = 4;
      this.checkFormUpdateBanner = true;
    },
    fetchDataIntro() {
      this.introduce = [];
      this.check = true;
      introduceemg.get().then((res) => {
        this.introduce = res.docs.map((doc) => {
          // this.btIntro()

          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
    updateDataIntro() {
      if (this.typeUpdate == 1) {
        introduceemg
          .doc(this.introduce[0].id)
          .update({
            intro: this.des,
          })
          .then(() => {
            swal("Thành công", "Bạn đã cập nhật thành công", "success");
          })
          .catch((error) => {
            swal("Lỗi", error.message, "error");
          });
      }

      if (this.typeUpdate == 2) {
        introduceemg
          .doc(this.introduce[0].id)
          .update({
            perpose: this.des,
          })
          .then(() => {
            swal("Thành công", "Bạn đã cập nhật thành công", "success");
          })
          .catch((error) => {
            swal("Lỗi", error.message, "error");
          });
      }
      if (this.typeUpdate == 3) {
        introduceemg
          .doc(this.introduce[0].id)
          .update({
            service: this.des,
          })
          .then(() => {
            swal("Thành công", "Bạn đã cập nhật thành công", "success");
          })
          .catch((error) => {
            swal("Lỗi", error, "error");
          });
      }
      if (this.typeUpdate == 4) {
        introduceemg
          .doc(this.introduce[0].id)
          .update({
            linkBanner: this.linkBanner,
            linkImage1: this.linkImage1,
            linkImage2: this.linkImage2,
          })
          .then(() => {
            swal("Thành công", "Bạn đã cập nhật thành công", "success");
          })
          .catch((error) => {
            swal("Lỗi", error, "error");
          });
      } else {
        swal("Nhắc nhở", "Bạn chưa thay đổi thông tin nào", "warning");
      }
    },

    onFileChangeBanner(e) {
      this.linkBanner = e.target.files[0];

      const ref = storage.ref().child("Hình ảnh banner").put(this.linkBanner);
      ref.on(
        "state_change",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // this.linkBanner = URL.createObjectURL(this.fileBanner);
        },
        () => {
          swal("Lỗi", "Không tải được ảnh lên", "error");
        },
        () => {
          this.uploadValue = 100;
          ref.snapshot.ref.getDownloadURL().then((url) => {
            this.linkBanner = url;
            console.log(this.linkBanner);
          });
        }
      );
    },
    onFileChangeImage1(e) {
      this.linkImage1 = e.target.files[0];

      const ref = storage.ref().child("Hình ảnh giới thiệu 1").put(this.linkImage1);
      ref.on(
        "state_change",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // this.linkBanner = URL.createObjectURL(this.fileBanner);
        },
        () => {
          swal("Lỗi", "Không tải được ảnh lên", "error");
        },
        () => {
          this.uploadValue = 100;
          ref.snapshot.ref.getDownloadURL().then((url) => {
            this.linkImage1 = url;
          });
        }
      );
    },
    onFileChangeImage2(e) {
      this.linkImage2 = e.target.files[0];

      const ref = storage.ref().child("Hình ảnh giới thiệu 2").put(this.linkImage2);
      ref.on(
        "state_change",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // this.linkBanner = URL.createObjectURL(this.fileBanner);
        },
        () => {
          swal("Lỗi", "Không tải được ảnh lên", "error");
        },
        () => {
          this.uploadValue = 100;
          ref.snapshot.ref.getDownloadURL().then((url) => {
            this.linkImage2 = url;
            console.log(this.linkBanner);
          });
        }
      );
    },
  },
  created() {
    this.fetchDataIntro();
  },
};
</script>

<style>
.btn-option {
  margin: 8px;
}
.product-container-btn {
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
}
.preview-img {
  margin-top: 16px;
  width: auto;
  max-height: 250px;
}
.preview-title {
  text-align: left !important;
}
</style>