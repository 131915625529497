<template>
  <div class="container">
    <div class="row">
      <div class="list-group col-lg-4">
        <h4>Dự án mới</h4>
        <button
          type="button"
          v-for="(newProject, index) in newProjectList"
          :key="index"
          :id="index"
          @click="clickBtn(index)"
          class="list-group-item list-group-item-action border-btn"
        >
          {{ index + 1 + ". " + newProject.nameProject }}
        </button>
      </div>

      <form class="col-lg-8">
        <div class="product-container-btn">
          <div>
            <button
              type="button"
              class="btn btn-option"
              :class="statusCreate"
              @click="btCreate"
            >
              Tạo mới
            </button>
            <button
              type="button"
              class="btn btn-option"
              :class="statusEdit"
              @click="btEdit"
            >
              Chỉnh sửa
            </button>
          </div>
          <div v-if="!typeBtn">
            <button
              class="btn btn-option btn-danger"
              @click.prevent="deleteData"
            >
              <i class="fa fa-trash" aria-hidden="true"></i> Xóa
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="inputAddress">Tên dự án mới</label>
          <input
            type="text"
            class="form-control"
            id="inputAddress"
            placeholder="Nhập tên dự án"
            v-model="inforProject.nameProject"
          />
        </div>
        <div class="form-group">
          <label for="inputAddress">Link hình ảnh</label>
          <input
            type="text"
            class="form-control"
            id="inputAddress"
            placeholder="Nhập link hình ảnh "
            v-model="inforProject.linkImage"
          />
          
        </div>
        <div class="form-group">
          <label for="inputAddress2">Mô tả dự án</label>
          <tinymce
            id="des"
            :plugins="plugins"
            v-model="inforProject.description"
          />
        </div>

        <button
          type="button"
          class="btn btn-primary mb-4"
          @click="btUpdateData"
        >
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
          Cập nhật
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { newProject } from "../../firebase-config";
import tinymce from "vue-tinymce-editor";
import swal from "sweetalert";
export default {
  components: {
    tinymce,
  },
  data() {
    return {
      newProjectList: [],
      inforProject: {},
      statusCreate: " btn-primary",
      statusEdit: " btn-outline-secondary",
      typeBtn: true,
      plugins: [
        "advlist autolink lists link image charmap print preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
      ],
    };
  },
  methods: {
    fetchData() {
      newProject.get().then((res) => {
        this.newProjectList = res.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
    btCreate() {
      this.statusCreate = " btn-primary";
      this.statusEdit = " btn-outline-secondary";
      for (var i = 0; i < this.newProjectList.length; i++) {
        document.getElementById(i).classList.remove("active");
      }
      this.inforProject = {};
      this.typeBtn = true;
    },
    btEdit() {
      this.statusCreate = " btn-outline-secondary";
      this.statusEdit = " btn-primary";
      this.typeBtn = false;
    },
    clickBtn(e) {
      for (var i = 0; i < this.newProjectList.length; i++) {
        if (i === e) {
          document.getElementById(i).classList.add("active");
          this.inforProject = this.newProjectList[i];
          this.statusCreate = " btn-outline-secondary";
          this.statusEdit = " btn-primary";
          this.typeBtn = false;
        } else {
          document.getElementById(i).classList.remove("active");
        }
      }
    },
    btUpdateData() {
      if (this.typeBtn) {
        newProject
          .add({
            description: this.inforProject.description,
              linkImage: this.inforProject.linkImage,
              nameProject: this.inforProject.nameProject,
          })
          .then(() => {
            this.inforProject = {};
            this.fetchData();
            swal("Thành công", "Bạn đã thêm sản phẩm thành công", "success");
          })
          .catch((error) => {
            swal("Lỗi", error, "error");
          });
      } else {
        if (Object.keys(this.inforProject).length === 0) {
          swal("Nhắc nhở", "Bạn chưa chọn sản phẩm để chỉnh sửa", "warning");
        } else {
          newProject
            .doc(this.inforProject.id)
            .update({
              description: this.inforProject.description,
              linkImage: this.inforProject.linkImage,
              nameProject: this.inforProject.nameProject,
            })
            .then(() => {
              swal(
                "Thành công",
                "Bạn đã chỉnh sửa dự án thành công",
                "success"
              );
            })
            .catch((error) => {
              swal("Lỗi", error, "error");
            });
        }
      }
    },
    deleteData() {
      if (Object.keys(this.inforProject).length === 0) {
        swal("Nhắc nhở", "Bạn chưa chọn dự án để xóa", "warning");
      } else {
        newProject
          .doc(this.inforProject.id)
          .delete()
          .then(() => {
            this.inforProject = {};
            this.fetchData();
            swal("Thành công", "Bạn đã xóa dự án thành công", "success");
          })
          .catch((error) => {
            swal("Lỗi", error, "error");
          });
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
h4 {
  margin-top: 8px !important;
  text-align: center !important;
}
.product-container-btn {
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
}
</style>