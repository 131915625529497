<template>
  <div class="container">
    <div class="row">
      <div class="list-group col-lg-4 ml-3">
        <h4>Sản phẩm</h4>
        <button
          type="button"
          v-for="(product, index) in productList"
          :key="index"
          :id="index"
          @click="clickBtn(index)"
          class="list-group-item list-group-item-action border-btn"
        >
          {{ index + 1 + ". " + product.nameProduct }}
        </button>
      </div>

      <form class="col-lg-7 ml-1 mr-4">
        <div class="product-container-btn">
          <div>
            <button
              type="button"
              class="btn btn-option"
              :class="statusCreate"
              @click="btCreate"
            >
              Tạo mới
            </button>
            <button
              type="button"
              class="btn btn-option"
              :class="statusEdit"
              @click="btEdit"
            >
              Chỉnh sửa
            </button>
          </div>
          <div v-if="!typeBtn">
            <button
              class="btn btn-option btn-danger"
              @click.prevent="deleteData"
            >
              <i class="fa fa-trash" aria-hidden="true"></i> Xóa
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="inputAddress">Tên sản phẩm</label>
          <input
            type="text"
            class="form-control"
            id="inputAddress"
            placeholder="Nhập tên sản phẩm"
            v-model="inforProduct.nameProduct"
          />
        </div>
        <div class="form-group">
          <div class="form-group mb-2 mr-3">
            <p class="preview-product-title">Hình ảnh</p>
            <input id="inputImageProduct" type="file" @change="onFileChange" />

            <div id="preview">
              <img
                class="preview-img"
                v-if="inforProduct.linkImage"
                :src="inforProduct.linkImage"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="inputAddress2">Mô tả sản phẩm</label>
          <tinymce
            id="des"
            :plugins="plugins"
            v-model="inforProduct.desProduct"
          />
        </div>

        <button
          type="button"
          class="btn btn-primary mb-4"
          @click="btUpdateData"
        >
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
          Cập nhật
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { products, storage } from "../../firebase-config";
import tinymce from "vue-tinymce-editor";
import swal from "sweetalert";
export default {
  components: {
    tinymce,
  },
  data() {
    return {
      productList: [],
      inforProduct: {},
      statusCreate: " btn-primary",
      statusEdit: " btn-outline-secondary",
      typeBtn: true,
      plugins: [
        "advlist autolink lists link image charmap print preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
      ],
    };
  },
  methods: {
    fetchData() {
      products.get().then((res) => {
        this.productList = res.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
    btCreate() {
      document.getElementById("inputImageProduct").value = "";
      this.statusCreate = " btn-primary";
      this.statusEdit = " btn-outline-secondary";
      for (var i = 0; i < this.productList.length; i++) {
        document.getElementById(i).classList.remove("active");
      }
      this.inforProduct = {};
      this.typeBtn = true;
    },
    btEdit() {
      this.statusCreate = " btn-outline-secondary";
      this.statusEdit = " btn-primary";
      this.typeBtn = false;
    },
    clickBtn(e) {
      for (var i = 0; i < this.productList.length; i++) {
        if (i === e) {
          document.getElementById(i).classList.add("active");
          this.inforProduct = this.productList[i];
          this.statusCreate = " btn-outline-secondary";
          this.statusEdit = " btn-primary";
          this.typeBtn = false;
        } else {
          document.getElementById(i).classList.remove("active");
        }
      }
    },
    btUpdateData() {
      if (this.typeBtn) {
        products
          .add({
            desProduct: this.inforProduct.desProduct,
            linkImage: this.inforProduct.linkImage,
            nameProduct: this.inforProduct.nameProduct,
          })
          .then(() => {
            this.inforProduct = {};
            this.fetchData();
            swal("Thành công", "Bạn đã thêm sản phẩm thành công", "success");
          })
          .catch((error) => {
            swal("Lỗi", error, "error");
          });
      } else {
        if (Object.keys(this.inforProduct).length === 0) {
          swal("Nhắc nhở", "Bạn chưa chọn sản phẩm để chỉnh sửa", "warning");
        } else {
          products
            .doc(this.inforProduct.id)
            .update({
              desProduct: this.inforProduct.desProduct,
              linkImage: this.inforProduct.linkImage,
              nameProduct: this.inforProduct.nameProduct,
            })
            .then(() => {
              swal(
                "Thành công",
                "Bạn đã chỉnh sửa sản phẩm thành công",
                "success"
              );
            })
            .catch((error) => {
              swal("Lỗi", error, "error");
            });
        }
      }
    },
    deleteData() {
      if (Object.keys(this.inforProduct).length === 0) {
        swal("Nhắc nhở", "Bạn chưa chọn sản phẩm để xóa", "warning");
      } else {
        products
          .doc(this.inforProduct.id)
          .delete()
          .then(() => {
            this.inforProduct = {};
            this.fetchData();
            swal("Thành công", "Bạn đã xóa sản phẩm thành công", "success");
          })
          .catch((error) => {
            swal("Lỗi", error, "error");
          });
      }
    },
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    onFileChange(e) {
      this.inforProduct.linkImage = e.target.files[0];
      var number = this.randomIntFromInterval(1, 99999999);
      const ref = storage
        .ref()
        .child("Sản phẩm " + number)
        .put(this.inforProduct.linkImage);
      ref.on(
        "state_change",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // this.linkBanner = URL.createObjectURL(this.fileBanner);
        },
        () => {
          swal("Lỗi", "Không tải được ảnh lên", "error");
        },
        () => {
          this.uploadValue = 100;
          ref.snapshot.ref.getDownloadURL().then((url) => {
            this.inforProduct.linkImage = url;
            console.log(this.inforProduct.linkImage);
          });
        }
      );
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
h4 {
  margin-top: 8px !important;
  text-align: center !important;
}
.product-container-btn {
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
}
.preview-product-title {
  color: #2c3e50;
}
</style>
