<template>
  <div class="sb-nav-fixed" :class="{ 'sb-sidenav-toggled': checkNav }">
    <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <!-- Navbar Brand-->
      <a class="navbar-brand ps-3" href="#">EMGlabvn</a>
      <!-- Sidebar Toggle-->
      <button
        class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
        id="sidebarToggle"
        @click="checkNav = !checkNav"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Navbar Search-->
      <form
        class="
          d-none d-md-inline-block
          form-inline
          ms-auto
          me-0 me-md-3
          my-2 my-md-0
        "
      ></form>
      <!-- Navbar-->
      <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><i class="fas fa-user fa-fw"></i
          ></a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
            <li><a class="dropdown-item" href="#!">Logout</a></li>
          </ul>
        </li>
      </ul>
    </nav>
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
          <div class="sb-sidenav-menu">
            <div class="nav">
              <div class="sb-sidenav-menu-heading">Chỉnh sửa</div>
              <a class="nav-link" @click="controlPage = 1">
                <div class="sb-nav-link-icon">
                  <i class="fas fa-address-card" aria-hidden="true"></i>
                </div>
                Giới thiệu
              </a>
              <a class="nav-link" @click="controlPage = 2">
                <div class="sb-nav-link-icon">
                  <i class="fas fa-plus-square" aria-hidden="true"></i>
                </div>
                Sản phẩm
              </a>
              <a class="nav-link" @click="controlPage = 3">
                <div class="sb-nav-link-icon">
                  <i class="fas fa-users" aria-hidden="true"></i>
                </div>
                Thành viên
              </a>
              <!-- <a
                class="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseLayouts"
                aria-expanded="false"
                aria-controls="collapseLayouts"
              >
                <div class="sb-nav-link-icon">
                  <i class="fas fa-users" aria-hidden="true"></i>
                </div>
                Thành viên
                <div class="sb-sidenav-collapse-arrow">
                  <i class="fas fa-angle-down"></i>
                </div>
              </a>
              <div
                class="collapse"
                id="collapseLayouts"
                aria-labelledby="headingOne"
                data-bs-parent="#sidenavAccordion"
              >
                <nav class="sb-sidenav-menu-nested nav">
                  <a class="nav-link" href="layout-static.html"
                    >Thành viên hiện tại</a
                  >
                  <a class="nav-link" href="layout-sidenav-light.html"
                    >Cựu thành viên</a
                  >
                </nav>
              </div> -->
              <a class="nav-link" @click="controlPage = 4">
                <div class="sb-nav-link-icon">
                  <i class="fas fa-envelope" aria-hidden="true"></i>
                </div>
                Email
              </a>
              <a class="nav-link" @click="controlPage = 5">
                <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                Dự án mới
              </a>
              <a class="nav-link" @click="controlPage = 6">
                <div class="sb-nav-link-icon"><i class="fa fa-users" aria-hidden="true"></i></div>
                Đối tác
              </a>
            </div>
          </div>
        </nav>
      </div>
      <!-- ở đây cho vue vào -->
      <div id="layoutSidenav_content">
        <EditIntroduceEmg v-if="controlPage == 1" />
        <EditProduct v-if="controlPage == 2" />
        <EditMember v-if="controlPage == 3" />
        <SeeMail v-if="controlPage == 4" />
        <EditNewProject v-if="controlPage == 5" />
        <EditPartner v-if="controlPage == 6" />
      </div>
    </div>
  </div>
</template>

<script>
import EditIntroduceEmg from "@/components/edit/EditIntroduceEmg.vue";
import EditNewProject from "@/components/edit/EditNewProject.vue";
import SeeMail from "@/components/edit/SeeMail.vue";
import EditProduct from "@/components/edit/EditProduct.vue";
import EditMember from "@/components/edit/EditMember.vue";
import EditPartner from "@/components/edit/EditPartner.vue";

export default {
  components: {
    EditProduct,
    EditMember,
    EditIntroduceEmg,
    SeeMail,
    EditNewProject,
    EditPartner,
  },
  data() {
    return {
      checkNav: false,
      controlPage: 1,
    };
  },
};
</script>

<style>
</style>